import React from 'react';
import { CheckCircleOutline, HighlightOff, RemoveCircleOutline } from '@styled-icons/material';

const ParsedStatusIcon = ({ isParsed }) => {
  if (isParsed === null) {
    return <RemoveCircleOutline size={24} />;
  }
  return isParsed === true ? (
    <CheckCircleOutline color="#01a77b" size={24} />
  ) : (
    <HighlightOff size={24} />
  );
};

export default ParsedStatusIcon;
