import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/lib/Async';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from 'shards-react';
import Debounce from 'es6-promise-debounce';
import Select from 'react-select';

import PageTitle from '../../../components/PageTitle';
import ShopService from '../../../services/ShopService';
import Constants from '../../../data/Constants';
import EstablishmentService from '../../../services/EstablishmentService';
import CredentialService from '../../../services/CredentialService';
import Errors from '../../../components/Errors';
import { debouncedFetchAccounts } from '../../../utils/accounts';
import { Can } from '../../../permissions/helper';
import { AlertModal } from '../../../components/common/AlertModal';

const ACCOUNT_ID_CHANGED_MESSAGE =
  'Al modificar la cuenta de los establecimientos recuerda que:\n  - Los establecimientos queadaran asignados al shop: \n     "establecimiento sin asignar"\n  - Las credenciales quedaran desasignadas\n  - El resto de los cambios sera omitido';

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      establishment: {
        id: props.establishmentId || null,
        number: '',
        account: {
          id: '',
          name: ''
        },
        shop: {
          id: '',
          name: ''
        },
        credentials: [],
        cardBrandId: ''
      },
      isNew: !props.establishmentId,
      errors: null,
      redirect: null,
      modalOpen: false
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      const establishmentService = EstablishmentService();
      establishmentService.get(this.state.establishment.id).then((data) => {
        data.credentials = this.transformCredentials(data.credentials);
        data.cardBrandId = data.cardBrandId === null ? '' : data.cardBrandId;
        this.setState({ establishment: data });
      });
    }
  }

  toggleModal() {
    this.setState((oldState) => ({ modalOpen: !oldState.modalOpen }));
  }

  transformCredentials = (credentials) => {
    return (credentials || []).map((credential) => {
      return {
        value: credential.id,
        label: `${credential.username} (${credential.infoProvider}) {${credential.id}}`
      };
    });
  };

  generateQueryForCredentials = (credentials) => {
    return credentials.map((credential) => credential.value);
  };

  // Array of [{label: '', value: ''}]
  formatSelectLabelAndValue = (array, value) => {
    let result = { value: '', label: '' };
    if (!value) {
      return result;
    }
    array.forEach((element) => {
      if (element.value === value) {
        result = { value: element.value, label: element.label };
      }
    });
    return result;
  };

  handleSubmit = (establishment) => {
    const establishmentService = EstablishmentService();
    const createOrUpdate = this.state.isNew
      ? establishmentService.create
      : establishmentService.update;

    const credentialIds = this.generateQueryForCredentials(establishment.credentials);

    createOrUpdate(
      establishment.number,
      credentialIds,
      establishment.account.id,
      establishment.shop && establishment.shop.id,
      establishment.id,
      establishment.cardBrandId
    ).then((data) => {
      if ((data.errors || []).length > 0) {
        this.setState({ errors: data.errors });
      } else {
        this.setState({ redirect: data.establishment.id });
      }
    });
  };

  debouncedFetchShops = Debounce(async (shopName) => {
    const shopParams = {
      shopName: shopName,
      accountNameOrId: this.state.establishment.account.id
    };

    const shopService = await ShopService();
    return shopService.list(shopParams).then((data) => {
      const transformedData = (data || []).map((shop) => {
        return { value: shop.id, label: `${shop.name} (${shop.account.name})` };
      });
      return transformedData;
    });
  }, 500);

  debouncedFetchCredentials = Debounce(async (inputValue) => {
    const credentialService = await CredentialService();
    return credentialService.list({ credentialUsername: inputValue }).then((data) => {
      return this.transformCredentials(data);
    });
  }, 500);

  render() {
    const cardTitle = this.state.isNew ? 'Crear' : 'Editar';
    const buttonText = this.state.isNew ? 'Crear' : 'Guardar';

    return this.state.redirect === null ? (
      <Container className="main-content-container px-4" fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-sm-left" sm="4" subtitle="" title="Establecimientos" />
        </Row>
        <Row>
          <Col>
            <Card className="mb-4" small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">{cardTitle}</h6>
              </CardHeader>
              <CardBody>
                <Errors errors={this.state.errors} />
                <Formik enableReinitialize initialValues={this.state.establishment}>
                  {(props) => {
                    const { values, isSubmitting, handleChange, handleBlur, setFieldValue } = props;

                    return (
                      <Form>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="id">Id (Opcional)</label>
                            <FormInput
                              id="id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Id (Migrando desde Argentina)"
                              type="text"
                              value={values.id}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="number">Number</label>
                            <FormInput
                              id="number"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              placeholder="Number"
                              type="text"
                              value={values.number}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="accountId">Account</label>
                            <Can
                              action="est:edit:acc"
                              unAuthorizedProps={{ isDisabled: !this.state.isNew }}
                            >
                              <AsyncSelect
                                cacheOptions={true}
                                id="accountId"
                                loadOptions={debouncedFetchAccounts}
                                onBlur={handleBlur}
                                onChange={(option) => {
                                  if (
                                    !this.state.isNew &&
                                    !window.confirm(ACCOUNT_ID_CHANGED_MESSAGE)
                                  ) {
                                    return;
                                  }
                                  setFieldValue('account.id', option.value);
                                  setFieldValue('account.name', option.label);
                                  // eslint-disable-next-line react/no-direct-mutation-state
                                  this.state.establishment.account.id = option.value;
                                }}
                                placeholder="Busca la Account por nombre"
                                value={
                                  values.account
                                    ? { value: values.account.id, label: values.account.name }
                                    : ''
                                }
                              />
                            </Can>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="form-group" md="4">
                            <label htmlFor="cardBrandId">Tarjeta</label>
                            <Select
                              className="basic-multi-select"
                              classNamePrefix="select"
                              id="cardBrandId"
                              name="cardBrandId"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (e.value !== this.state.establishment.cardBrandId) {
                                  this.toggleModal();
                                }
                                setFieldValue('cardBrandId', e.value);
                              }}
                              options={Constants.cardBrands}
                              placeholder={'Tarjeta'}
                              value={this.formatSelectLabelAndValue(
                                Constants.cardBrands,
                                values.cardBrandId
                              )}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="shopId">Shop</label>
                            <AsyncSelect
                              cacheOptions={true}
                              id="shopId"
                              loadOptions={this.debouncedFetchShops}
                              onBlur={handleBlur}
                              onChange={(option) => {
                                setFieldValue('shop.id', option.value);
                                setFieldValue('shop.name', option.label);
                              }}
                              placeholder="Busca el Shop por nombre"
                              value={
                                values.shop
                                  ? { value: values.shop.id, label: values.shop.name }
                                  : ''
                              }
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="form-group" md="4">
                            <label htmlFor="credentialIds">Credentials</label>
                            <Can
                              action="est:edit:cred"
                              unAuthorizedProps={{ isDisabled: !this.state.isNew }}
                            >
                              <AsyncSelect
                                cacheOptions={true}
                                id="credentialIds"
                                isMulti={true}
                                loadOptions={this.debouncedFetchCredentials}
                                onBlur={handleBlur}
                                onChange={(option) => {
                                  setFieldValue('credentials', option);
                                }}
                                placeholder="Busca la Credential por nombre de usuario"
                                value={values.credentials || ''}
                              />
                            </Can>
                          </Col>
                        </Row>
                        <Button
                          className="btn-primary"
                          disabled={isSubmitting}
                          onClick={() => this.handleSubmit(values)}
                        >
                          {buttonText}
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AlertModal
          content="Estas asignando una nueva marca de tarjeta a este establecimiento y el cambio impactará en todos los documentos vinculados al mismo."
          headerText="Atencion"
          open={this.state.modalOpen}
          toggle={() => this.toggleModal()}
        />
      </Container>
    ) : (
      <Redirect to={`/admin/establishments/${this.state.redirect}`} />
    );
  }
}

Editor.propTypes = {
  establishmentId: PropTypes.string
};

export default Editor;
