import GraphqlClient from './GraphqlClients';
import gql from 'graphql-tag';

const DocumentService = async () => {
  const gqlClient = GraphqlClient();

  const getParsedQuery = gql`
    query ParsedDocument($filter: parser_DocumentsFilter) {
      document: parser_documents(filter: $filter) {
        link
      }
    }
  `;

  const getScrapedQuery = gql`
    query ScrapedDocument($id: String!) {
      document: scp_document(id: $id) {
        requiresTransformation
        relatedDocumentId
        link
      }
    }
  `;

  const reparseScraperMutation = gql`
    mutation Reparse($documentIds: [String!]) {
      operation: scp_resendToParser(documentIds: $documentIds) {
        documents {
          id
          status
        }
      }
    }
  `;

  const reparseParserMutation = gql`
    mutation Reparse($scrapedDocumentIds: [String!]) {
      operation: parser_reparse(scrapedDocumentIds: $scrapedDocumentIds) {
        documents {
          id
          status
        }
      }
    }
  `;

  return {
    getParsedDocuments: (ids) => {
      return gqlClient
        .query({
          query: getParsedQuery,
          variables: { filter: { ids: ids } }
        })
        .then((data) => data.data.document)
        .catch(() => null); // TODO: do something
    },
    getScrapedDocument: (id) => {
      return gqlClient
        .query({
          query: getScrapedQuery,
          variables: { id: id }
        })
        .then((data) => data.data.document)
        .catch(() => null); // TODO: do something
    },
    reparseScraper: (documentIds) => {
      return gqlClient
        .mutate({
          mutation: reparseScraperMutation,
          variables: { documentIds }
        })
        .then((data) => data.data.operation.documents)
        .catch(() => null);
    },
    reparseParser: (scrapedDocumentIds) => {
      return gqlClient
        .mutate({
          mutation: reparseParserMutation,
          variables: { scrapedDocumentIds }
        })
        .then((data) => data.data.operation.documents)
        .catch((error) => {
          throw error;
        });
    }
  };
};

export default DocumentService;
