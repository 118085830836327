import interdinLogo from '../assets/images/info-provider-icons/interdin.png';
import cardnetLogo from '../assets/images/info-provider-icons/cardnet.png';
import visaLogo from '../assets/images/info-provider-icons/visa.png';
import firstDataLogo from '../assets/images/info-provider-icons/first-data.png';
import amexLogo from '../assets/images/info-provider-icons/amex.png';
import naranjaLogo from '../assets/images/info-provider-icons/naranja.png';
import cabalLogo from '../assets/images/info-provider-icons/cabal.png';
import prismaLogo from '../assets/images/info-provider-icons/prisma.png';
import mercadopagoLogo from '../assets/images/info-provider-icons/mercadopago.png';
import argentinaIcon from '../assets/images/flag-icons/argentina-flag-round-icon-256.png';
import uruguayIcon from '../assets/images/flag-icons/uruguay-flag-round-icon-256.png';
import ecuadorIcon from '../assets/images/flag-icons/ecuador-flag-round-icon-256.png';
import paraguayIcon from '../assets/images/flag-icons/paraguay-flag-round-icon-256.png';
import dominicanIcon from '../assets/images/flag-icons/dominican-republic-flag-round-icon-256.png';

const constants = {
  scraperProviders: [
    'amex',
    'amex_regular_uy',
    'cabal',
    'cabal_new',
    'credimatic',
    'creditel',
    'first_data',
    'first_data_new',
    'first_data_new_uy',
    'first_data_regular_uy',
    'first_data_sftp',
    'guayaquil',
    'naranja',
    'oca',
    'visa',
    'visanet'
  ],
  ignoredScraperProviders: ['naranja', 'visanet'],
  scraperProvidersOrder: {
    amex: 1,
    cabal: 2,
    first_data: 3,
    first_data_new: 4,
    naranja: 5,
    visa: 6
  },
  scrapperOkColor: ['brandGreen', 'medium'],
  scrapperDocMissingColor: ['alerta', 'regular'],
  scrapperParserFailColor: ['sol', 'regular'],
  scrapperIgnoreColor: ['brandWhite', 'bold'],
  scraperDocumentTypeLabels: {
    electronic_payments: 'Pagos Electronicos',
    payments: 'Liquidaciones',
    monthly_payments: 'Pagos Mensuales'
  },
  scraperDocumentType: {
    argentina: ['electronic_payments', 'monthly_payments'],
    default: ['electronic_payments', 'payments', 'monthly_payments']
  },
  scraperDocumentTypeByInfoProvider: {
    amex_regular_uy: ['payments', 'monthly_payments'],
    amex: ['electronic_payments', 'monthly_payments'],
    cabal_new: ['electronic_payments', 'monthly_payments'],
    cabal: ['electronic_payments', 'monthly_payments'],
    credimatic: ['payments', 'monthly_payments'],
    creditel: ['payments', 'monthly_payments'],
    first_data_new_uy: ['electronic_payments', 'monthly_payments'],
    first_data_new: ['electronic_payments', 'monthly_payments'],
    first_data_regular_uy: ['payments', 'monthly_payments'],
    first_data_sftp: ['electronic_payments', 'monthly_payments'],
    first_data: ['electronic_payments', 'monthly_payments'],
    guayaquil: ['payments', 'monthly_payments'],
    naranja: ['electronic_payments', 'monthly_payments'],
    oca: ['payments', 'monthly_payments'],
    pacific: ['payments', 'monthly_payments'],
    visa: ['electronic_payments', 'monthly_payments'],
    visanet: ['payments', 'monthly_payments']
  },
  electronicPayment: 'electronic_payments',
  regularPayments: 'payments',
  scraperDocumentsPerPage: 30,
  defaultAccountId: 'acc00000-0000-0000-0000-000000000000',
  statusIcons: {
    ok: 'check-circle',
    warning: 'warning',
    pending: 'cached',
    blocked: 'block'
  },
  statusIconsClasses: 'material-icons status-icon',
  booleanIconsClasses: 'material-icons status-icon',
  booleanIcons: {
    true: 'check-circle',
    false: 'block'
  },
  providerLogos: {
    amex: amexLogo,
    amex_regular: amexLogo,
    amex_api: amexLogo,
    interdin: interdinLogo,
    cardnet: cardnetLogo,
    prisma: prismaLogo,
    visa: visaLogo,
    first_data: firstDataLogo,
    first_data_old: firstDataLogo,
    first_data_new: firstDataLogo,
    first_data_regular: firstDataLogo,
    first_data_sftp: firstDataLogo,
    naranja: naranjaLogo,
    naranja_regular: naranjaLogo,
    cabal: cabalLogo,
    cabal_regular: cabalLogo,
    cabal_new: cabalLogo,
    mercadopago: mercadopagoLogo
  },
  // Source: https://www.countryflags.com
  countryIcons: {
    argentina: argentinaIcon,
    uruguay: uruguayIcon,
    ecuador: ecuadorIcon,
    paraguay: paraguayIcon,
    dominicana: dominicanIcon
  },
  movementTypes: {
    'Movements::Transaction': 'Transaction',
    'Movements::Adjustment': 'Adjustment',
    'Movements::Chargeback': 'Chargeback',
    'Movements::Refund': 'Refund',
    'Movements::Rejection': 'Rejection',
    'Movements::TransactionRetry': 'TransactionRetry'
  },
  infoProviders: [
    { value: [], label: 'Todos los proveedores' },
    {
      value: [
        'amex_regular',
        'cabal_regular',
        'first_data_new',
        'first_data_old',
        'naranja_regular',
        'prisma',
        'prisma_new'
      ],
      label: 'Solo regular'
    },
    {
      value: [
        'amex',
        'amex_api',
        'cabal',
        'cabal_new',
        'first_data',
        'naranja',
        'visa',
        'interdin',
        'cardnet',
        'mercadopago',
        'prisma_new'
      ],
      label: 'Solo electrónica'
    },
    { value: ['amex_regular'], label: 'Amex regular' },
    { value: ['amex'], label: 'Amex electrónica' },
    { value: ['amex_api'], label: 'Amex API' },
    { value: ['cabal_regular'], label: 'Cabal regular' },
    { value: ['cabal_new'], label: 'Cabal electrónica nuevo' },
    { value: ['cabal'], label: 'Cabal electrónica' },
    { value: ['interdin'], label: 'Interdin' },
    { value: ['cardnet'], label: 'Cardnet' },
    { value: 'credimatic', label: 'Credimatic' },
    { value: ['first_data_new'], label: 'FirstData nuevo' },
    { value: ['first_data_old'], label: 'FirstData viejo' },
    { value: ['first_data'], label: 'FirstData electrónica' },
    { value: ['first_data_sftp'], label: 'FirstData SFTP' },
    { value: ['guayaquil'], label: 'Guayaquil' },
    { value: ['mercadopago'], label: 'Mercadopago' },
    { value: ['naranja_regular'], label: 'Naranja regular' },
    { value: ['naranja'], label: 'Naranja electrónica' },
    { value: ['prisma'], label: 'Prisma regular' },
    { value: ['prisma_new'], label: 'Prisma regular nuevo' },
    { value: ['visa'], label: 'Visa electrónica' }
  ],
  infoProvidersScraper: [
    { value: 'amex', label: 'Amex' },
    { value: 'cabal', label: 'Cabal' },
    { value: 'cabal_new', label: 'Cabal Nuevo' },
    { value: 'first_data', label: 'First Data' },
    { value: 'first_data_new', label: 'First Data Nuevo' },
    { value: 'first_data_sftp', label: 'First Data SFTP' },
    { value: 'naranja', label: 'Naranja' },
    { value: 'visa', label: 'Visa' },
    { value: 'prisma_new', label: 'Prisma regular nuevo' }
  ],
  infoProvidersSimple: [
    { value: 'amex_regular', label: 'Amex regular' },
    { value: 'amex_regular_uy', label: 'Amex regular UY' },
    { value: 'amex', label: 'Amex electrónica' },
    { value: 'amex_api', label: 'Amex API' },
    { value: 'cabal_regular', label: 'Cabal regular' },
    { value: 'cabal_new', label: 'Cabal electrónica nuevo' },
    { value: 'cabal', label: 'Cabal electrónica' },
    { value: 'credimatic', label: 'Credimatic' },
    { value: 'creditel', label: 'Creditel regular' },
    { value: 'interdin', label: 'Interdin' },
    { value: 'cardnet', label: 'Cardnet' },
    { value: 'first_data_new', label: 'FirstData nuevo' },
    { value: 'first_data_new_uy', label: 'FirstData nuevo UY' },
    { value: 'first_data_old', label: 'FirstData viejo' },
    { value: 'first_data_regular', label: 'FirstData regular' },
    { value: 'first_data_regular_uy', label: 'FirstData regular UY' },
    { value: 'first_data', label: 'FirstData electrónica' },
    { value: 'first_data_sftp', label: 'FirstData SFTP' },
    { value: 'guayaquil', label: 'Guayaquil' },
    { value: 'mercadopago', label: 'Mercadopago' },
    { value: 'naranja_regular', label: 'Naranja regular' },
    { value: 'naranja', label: 'Naranja electrónica' },
    { value: 'oca', label: 'Oca regular' },
    { value: 'prisma', label: 'Prisma regular' },
    { value: 'prisma_new', label: 'Prisma regular nuevo' },
    { value: 'visa', label: 'Visa electrónica' },
    { value: 'visanet', label: 'Visanet regular' }
  ],
  documentTypes: [
    { value: 'anticipations', label: 'Preliquidaciones' },
    { value: 'electronic_anticipations', label: 'Preliquidaciones electrónicas' },
    { value: 'electronic_payments', label: 'Liquidaciones electrónicas' },
    { value: 'monthly_payments', label: 'Liquidaciones mensuales' },
    { value: 'movements', label: 'Movimientos' },
    { value: 'multiple', label: 'Multiple' },
    { value: 'payments', label: 'Liquidaciones' },
    { value: 'chargebacks', label: 'Contracargos' }
  ],
  cardBrands: [
    { value: '', label: 'Todos las tarjetas' },
    { value: 'amex', label: 'Amex' },
    { value: 'argencard', label: 'Argencard' },
    { value: 'cabal', label: 'Cabal' },
    { value: 'cencosud', label: 'Cencosud' },
    { value: 'centrocard', label: 'Centrocard' },
    { value: 'cmr', label: 'CMR' },
    { value: 'diners', label: 'Diners' },
    { value: 'discover', label: 'Discover' },
    { value: 'interdin', label: 'Interdin' },
    { value: 'jcb', label: 'JCP' },
    { value: 'lider', label: 'Lider' },
    { value: 'maestro', label: 'Maestro' },
    { value: 'mastercard', label: 'Mastercard' },
    { value: 'mastercard_debit', label: 'Mastercard Débito' },
    { value: 'mercadopago', labe: 'Mercadopago' },
    { value: 'naranja', label: 'Naranja' },
    { value: 'nativa', label: 'Nativa' },
    { value: 'nevada', label: 'Nevada' },
    { value: 'pacific', label: 'Pacific' },
    { value: 'ticket_nacion', label: 'Ticket Nación' },
    { value: 'union_pay', label: 'Union Pay' },
    { value: 'visa', label: 'Visa' },
    { value: 'visa_debit', label: 'Visa Débito' }
  ],
  credentialStatuses: [
    { value: '', label: 'Todos los estados' },
    { value: 'ok', label: 'Ok' },
    { value: 'warning', label: 'Por vencer' },
    { value: 'blocked', label: 'Bloqueada' },
    { value: 'pending', label: 'Pendiente' },
    { value: 'incorrect', label: 'Incorrecta' },
    { value: 'disabled', label: 'Deshabilitada' },
    { value: 'deleted', label: 'Eliminada' }
  ],
  months: [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
  ],
  countries: [
    { value: 'ecuador', label: 'Ecuador' },
    { value: 'argentina', label: 'Argentina' },
    { value: 'dominicana', label: 'Dominicana' },
    { value: 'uruguay', label: 'Uruguay' }
  ],
  defaultTenant: 'argentina',
  elementsPerPage: 20,
  userRoles: [
    { value: 'superuser', label: 'Dueño de la cuenta' },
    { value: 'admin', label: 'Manager' },
    { value: 'viewer', label: 'Usuario Comun' }
  ],
  productNames: [
    { value: 'card', label: 'Card' },
    { value: 'conciliation', label: 'Conciliación' }
  ],
  jobStatuses: [
    { value: 'pending', label: 'Pendiente' },
    { value: 'enqueued', label: 'Encolado' },
    { value: 'external_enqueued', label: 'Encolado ext.' },
    { value: 'running', label: 'Scrapeando' },
    { value: 'login_failed', label: 'Falló el login' },
    { value: 'failed', label: 'Fallado' },
    { value: 'failed_and_finished', label: 'Fallado y descartado' },
    { value: 'completed_by_scraper', label: 'Terminado por scraper' },
    { value: 'completed_by_scheduler', label: 'Terminado por scheduler' },
    { value: 'found', label: 'Documento encontrado' },
    { value: 'already_found', label: 'Documento encontrado' },
    { value: 'not_found_yet', label: 'Sin documento (todavía)' },
    { value: 'not_found', label: 'Sin documento' }
  ],
  reparseStatuses: [
    { value: 'not_found', label: 'No encontrado' },
    { value: 'send_to_parser', label: 'Enviado al parser' },
    { value: 'enqueued', label: 'Encolado' }
  ]
};

export default constants;
