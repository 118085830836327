import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SupportDashboard from './dashboard/Dashboard';
import { default as SupportMonthlySummary } from './monthlyComparator/summary/List';
import { default as SupportMonthlyDetailed } from './monthlyComparator/detailed/List';
import { default as SupportMonthlyMetrics } from './monthlyMetrics/List';
import { default as SupportMonthlyComparison } from './monthlyComparison/List';
import { default as SupportTracker } from './jobTracker/List';
import { default as FetchEstablishments } from './fetchEstablishments';
import { SupportReparse } from './reparse/Reparse';
import ManualScraper from './ManualScraper';

const Support = () => (
  <Switch>
    <Route component={SupportDashboard} exact path="/support" />
    <Route component={SupportMonthlySummary} exact path="/support/monthly_summary" />
    <Route component={SupportMonthlyDetailed} exact path="/support/monthly_detailed" />
    <Route
      component={SupportMonthlyDetailed}
      exact
      path="/support/monthly_detailed/:monthlyPaymentId"
    />
    <Route component={SupportMonthlyMetrics} exact path="/support/monthly_metrics" />
    <Route component={SupportMonthlyComparison} exact path="/support/monthly_comparisons" />
    <Route component={ManualScraper} path="/support/manual_scraper" />
    <Route component={SupportTracker} exact path="/support/job_tracker" />
    <Route component={FetchEstablishments} exact path="/support/fetch_establishments" />
    <Route component={SupportReparse} exact path="/support/reparse" />
  </Switch>
);

export default Support;
